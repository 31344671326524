<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgImageMedia ref="dlgImageMedia" :visible="dlgVisibleImageMedia" :route_type="cpFiles.route_type" :section="dlgImageMediaSection" @close="dlgImageMediaClose"></DlgImageMedia>
        <upload-files :visible="cpFiles.showDialog" :file_type="cpFiles.file_type" :route_type="cpFiles.route_type" :caller="caller" @upload-done="uploadDone" @close="cpFiles.showDialog=false"></upload-files>

        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Merchant Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
            <v-toolbar dark class="panel1"><v-toolbar-title>Info</v-toolbar-title>
                <v-spacer></v-spacer>
                
                <v-btn icon dark v-for="menu in menus" :key="menu.title" @click="menuClick(menu.type)"><v-icon>{{menu.icon}}</v-icon></v-btn>
                <v-btn icon dark @click="save"><v-icon>save</v-icon></v-btn>
            </v-toolbar>             
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                <v-form ref="mainForm">
                    <v-container class="pt-2">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-model="form.merchant_id" disabled label="ID" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.merchant_code" label="Code" :rules="inputRules" outlined></v-text-field>
                                <v-text-field v-model="form.merchant_name" label="Name" :rules="inputRules" outlined></v-text-field>
                                <v-select
                                    v-model = "form.merchant_type_code"
                                    :items="merchant_type_list"
                                    item-text="description"
                                    item-value="code"
                                    label="Type"
                                    standard
                                    :rules="inputRules"
                                     outlined
                                ></v-select>
                                <v-text-field v-model="form.website" label="Website" outlined></v-text-field>
                                <v-textarea v-model="form.long_description" label="Description" outlined rows="10"></v-textarea>
                            </v-col>
                        </v-row>

                    </v-container>
                </v-form>
                </v-card>
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.logo_file_name" :src="this.form.file_url + this.form.logo_file_name"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Logo</v-card-title>
                            <v-card-subtitle>{{ form.logo_file_name}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.logo_file_name')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('logo_file_name')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>

                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card>
                <v-card class="mt-5">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.display_pic1" :src="this.form.file_url + this.form.display_pic1"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Picture 1</v-card-title>
                            <v-card-subtitle>{{ form.display_pic1}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.display_pic1')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('display_pic1')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>
                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card>
                <v-card class="mt-5">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.display_pic2" :src="this.form.file_url + this.form.display_pic2"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Picture 2</v-card-title>
                            <v-card-subtitle>{{ form.display_pic2}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.display_pic2')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('display_pic2')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card>
                <v-card class="mt-5">
                    <div class="d-flex flex-no-wrap">
                        <v-avatar class="ma-3" size="125" tile>
                            <v-img v-if="form.display_pic3" :src="this.form.file_url + this.form.display_pic3"></v-img>
                            <v-img v-else src="../../assets/image/no-image.png"></v-img>
                        </v-avatar>
                        
                        <div>
                            <v-card-title class="headline">Picture 3</v-card-title>
                            <v-card-subtitle>{{ form.display_pic3}}</v-card-subtitle>
                            <v-card-actions>
                                <v-btn class="ml-2 mt-5 btnUpload" dark @click="showUpload('form.display_pic3')"><v-icon>mdi-plus</v-icon>Upload</v-btn>
                                <v-btn class="ml-2 mt-5 btnLibrary" dark @click="showDlgImageMedia('display_pic3')"><v-icon>mdi-folder-image</v-icon>Library</v-btn>                            </v-card-actions>
                        </div>
                        
                    </div>
                </v-card>
                    <div class="mt-3">
                        <v-btn color="cbtnSave" dark @click="save">
                                    <v-icon left dark>save</v-icon>
                                        Save
                                </v-btn>
                    </div>
                
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import UploadFiles from "@/components/UploadFiles";
import DlgImageMedia from '@/components/DlgImageMedia'
import ApMerchantService from "@/services/ApMerchantService"
export default {
  components:{DlgMessage,UploadFiles,DlgImageMedia},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Merchants',disabled: false,href:'/merchants',},
              {text:'Detail',disabled: false,href:'#',},
              ],

            merchant_type_list:[],
            status_list:[],
            
            encMerchantName:'',

            inputRules: [
             v => !!v || 'This field is required'
            ],
           
            form:{login_id:null,file_url:"",logo_file_name:"",display_pic1:"",display_pic2:"",display_pic3:""},
            logo:'',

            cpFiles:{
                file_type:'image',
                route_type:'merchants',
                showDialog:false,
            },
            dpicker:{menu1: false,menu2: false,},
            menus:[
                { title:"Outlet", type:1, route:"/merchants/outlets/", icon:"mdi-store" },
                { title:"Edc", type:2, route:"/merchants/edc/", icon:"mdi-calculator" },
                { title:"Users", type:2, route:"/merchants/users/", icon:"mdi-account-multiple" },
            ],
            dlgVisibleImageMedia:false,           
            dlgImageMediaSection:"",            

    }
  },
  async created() {
      this.form.merchant_id = this.$route.params.merchant_id;
      this.viewDetail();
  },
  
  methods:{

    async viewDetail(){
        try{
            ApMerchantService.view_merchant(this.form).then((res) => {
                this.form = res.data.data;
                for (const [key, value] of Object.entries(this.form)) {
                    if(value == null){
                        this.form[key] = "";
                    }
                }                
                //console.log(res.data);
                this.status_list = res.data.statusList; 
                this.merchant_type_list = res.data.merchantTypeList;
                this.encMerchantName = btoa(this.form.merchant_name);

                
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Merchant',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    async save(){
        try{
            ApMerchantService.update_merchant(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','Update Merchants','Success.');
            }).catch((e)=>{
                //console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','Update Merchants',e.response.data.error_message);
            })
            ;
        } catch(e) {
            //console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }
    },
    
    uploadDone(e){
        //console.log(e);
        this.form.file_url = e.fileUrl;
        //console.log("this." + e.caller + "='" + e.fileName + "'");
        eval("this." + e.caller + "='" + e.fileName + "'");
        //this.form.logo_file_name = e.fileName;
        //console.log(e.caller);
    },

    showUpload(uploadType){
        //console.log('showupload');
        this.cpFiles.file_type = 'image';
        this.cpFiles.route_type = 'merchants';
        this.caller = uploadType;
        //console.log(uploadType)
        this.cpFiles.showDialog = true;
    },
    dlgClose(){
        this.visible = false;        
    },
    menuClick(type){
        switch (type) {
            case 1:
                this.$router.push({ name: 'outlet-list', 
                    params: { 
                        merchant_id: this.form.merchant_id,
                        enc_merchant_name: this.encMerchantName,
                    } 
                })
                break;
            case 2:
                this.$router.push({ name: 'edc-list', 
                    params: { 
                        merchant_id: this.form.merchant_id,
                        enc_merchant_name: this.encMerchantName,
                    } 
                })
                break;        
            default:
                this.$router.back()
                break;
        }
    },

    dlgImageMediaClose(selectedImage){
        this.dlgVisibleImageMedia = false;
       

        // if(selectedImage.imageFileName && selectedImage.section == "logo_file_name"){
        //     this.form.logo_file_name = selectedImage.imageFileName
        //     this.form.file_url = selectedImage.fileUrl
        //     return
        // }
       

        this.form[selectedImage.section] = selectedImage.imageFileName;
        if(selectedImage.fileUrl)
            this.form.file_url = selectedImage.fileUrl;

        //console.log("form",this.form);
        // if(selectedImage.imageFileName && selectedImage.section =="items"){
        //     let e = {fileName:selectedImage.imageFileName,urlPreview:selectedImage.fileUrl}
        //     let totalImage = this.ciImages.length;
        //     let ciImageNew = {sequence:totalImage+1*10,file_url:e.urlPreview,image_file_name:e.fileName,preview:"",status:"ACTIVE"}
        //     this.ciImages.push(ciImageNew)
        //     this.saveIndicator = "(*)"
        //     //console.log(ciImageNew);

        // }
    },
    showDlgImageMedia(section){
        this.dlgImageMediaSection = section;
        this.dlgVisibleImageMedia = true;
        
        
    },


  }
}
</script>